export default [
  {
    title: '',
    fields: [
      {
        type: 'input',
        inputType: 'email',
        width: '100%',
        name: 'email',
        label: 'Email Address',
        required: true,
      },
      {
        type: 'submit',
        width: '100%',
        buttonLabel: 'Reset Password',
      },
    ],
  },
];

import React, { useContext } from 'react';

import passwordFields from './data';

import { FirebaseContext } from '../Firebase';
import Form from '../Form';

const successMessage =
  'Please check your email for instructions on how to reset your password.';

export default function ForgottenPasswordForm() {
  const firebase = useContext(FirebaseContext);
  const handleSubmit = ({ data, success, error }) => {
    firebase
      .doPasswordReset(data.email)
      .then(() => {
        success(successMessage);
      })
      .catch(err => {
        error(err);
      });
  };
  return (
    <Form
      name="forgotten-password"
      fields={passwordFields}
      action={handleSubmit}
      // debug
    />
  );
}

import React from 'react';

import Layout from '../../layouts';
import PageTitle from '../../components/Subpage/title';
import Container from '../../components/Container';
import ForgottenPassword from '../../components/ForgottenPassword';

const page = {
  name: 'Forgotten Password',
  slug: 'account/forgotten-password',
};

export default function ForgottenPasswordPage({ location }) {
  const prevPage = location.state && location.state.prevPage;
  return (
    <Layout title={page.name} location={`/${page.slug}/`} prevPage={prevPage}>
      <PageTitle title={page.name} />
      <Container>
        <ForgottenPassword />
      </Container>
    </Layout>
  );
}

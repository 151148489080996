import React from 'react';

import { LinkPlaceholder } from './placeholders';
import { additionalActions, linkStyle } from './style';

import './login.css';

import loadComponents from '../Loadable';

const Link = loadComponents('link');

export default function LoginLinks({ data }) {
  return (
    <div className="loginLinks" style={additionalActions}>
      {data.map((item, i) => (
        <Link
          key={item.label}
          to={item.link}
          content={item.label}
          style={i !== 0 && linkStyle}
          fallback={<LinkPlaceholder style={i !== 0 && linkStyle} />}
        />
      ))}
    </div>
  );
}
